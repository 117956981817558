<template>
  <div class="main">
    <InternalMovingMaterialTabs />
  </div>
</template>


<script>
import InternalMovingMaterialTabs from '@/components/InternalMaterials/InternalMovingMaterialTabs.vue'

export default {
  components: {
    InternalMovingMaterialTabs,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.main{
  display: flex;
  justify-content: flex-start !important;
  gap: 10px;
}
@media screen and (max-width: 1200px) {
  .main{
    padding: 90px 15px 15px 20px;
  }
}
</style>
