<template>
  <div style="width: 100%">
    <b-tabs
      v-model="tabIndex"
    >
      <b-tab class="displayFlex">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("InternalMoving") }}</strong>
        </template>
        <InternalMaterials
          style="width: 100%;"
          @tab2="goToTab2"
        />
        <AddToInternalMovingMaterials
          style="width: 100%;"
        />
      </b-tab>
      <b-tab
        class="displayFlex"
        @click="callInPending"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("FactoryLog") }}</strong>
        </template>
        <InPendingMaterials
          style="width: 100%;"
        />
      </b-tab>
      <b-tab
        class="displayFlex"
        @click="goToTab3"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToManufactory") }}</strong>
        </template>
        <ShippedArticlesMaterials
          style="width: 100%;"
        />
      </b-tab>
      <b-tab @click="callInStore">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("StoreLog") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedToStoreMaterials />
        </div>
      </b-tab>
      <b-tab @click="goToTab4">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToStore") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedMaterialsStore />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InternalMaterials from './InternalMaterials.vue'
import AddToInternalMovingMaterials from './AddToInternalMovingMaterials.vue'
import InPendingMaterials from './InPendingMaterials.vue'
import ShippedArticlesMaterials from './ShippedArticlesMaterials.vue'
import ShippedToStoreMaterials from './ShippedToStoreMaterials.vue'
import ShippedMaterialsStore from './ShippedMaterialsStore.vue'





export default {
  components: {
    InternalMaterials,
    AddToInternalMovingMaterials,
    InPendingMaterials,
    ShippedArticlesMaterials,
    ShippedToStoreMaterials,
    ShippedMaterialsStore,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {

  },
  mounted() {
    this.orderItemStates()
  },
  methods: {
    ...mapActions(['filterItemMovement', 'filterOrderMovement', 'teamNames', 'orderItemStates']),
    goToTab2() {
      this.tabIndex = 1
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    callInPending() {
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    callInStore() {
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab3() {
      this.filterOrderMovement({
        shipType: 'ShipToManufactory',
        deliverDate: null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab4() {
      this.filterOrderMovement({
        shipType: 'ShipToStore',
        deliverDate: null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
  },
}
</script>

<style scoped>
.displayFlex {
  display: flex;
  gap: 20px;
  padding-top: 20px;
}
@media screen and (max-width: 1200px) {
  .displayFlex {
    display: flex;
    flex-direction: column;
  }
}
</style>
