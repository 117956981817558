<template>
  <div>
    <div style="width: 100%; margin-top: 0px;">
      <div style="display:flex;justify-content:space-between;align-items:flex-end;margin-bottom: 10px;">
        <div>
          <b-form-group
            :label="$t('OrderState')"
          >
            <vue-select
              v-model="orderStates"
              class="custom-select2"
              style="width: 250px;"
              label="text"
              :clearable="false"
              :reduce="(e) => e.value"
              :placeholder="$t('Select')"
              :options="stateOptionsFiltered"
            />
          </b-form-group>
        </div>
        <div style="display: flex;gap: 5px;">
          <button
            v-if="!orderItemIds.length == 0"
            v-b-toggle.view-materials-trans
            v-b-tooltip.hover
            class="button"
            :title="$t('ClickToSeeAllMaterials')"
            @click="showMaterials"
          >
            {{ $t('ViewMaterials') }}
          </button>
          <button
            v-b-modal.ship-order
            class="button"
          >
            {{ $t('ShipToStore') }}
          </button>
        </div>
      </div>
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th style="display: flex;gap: 5px">
              <input
                type="checkbox"
                :checked="selectAll"
                @change="toggleSelectAll"
              >
              {{ $t('Select') }}
            </th>
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in getFilterItemsArticlesState"
            :key="index"
          >
            <td>
              <input
                type="checkbox"
                :value="item.orderItemId"
                :checked="isItemChecked(item)"
                @change="event => toggleOrderItem(event, index)"
              >
            </td>
            <td>
              {{ item.orderNumber }}
            </td>
            <td>
              {{ item.articleNumber }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="getTotalItemsArticlesState.length > 15">
        <b-pagination
          v-model="page"
          :total-rows="getTotalItemsArticlesState"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <ShipOrderToStore
        :order-item-ids="orderItemIds"
        @onSubmit="moveOrders"
      />
      <ViewMaterialsTransferred
        @onCancelToggle="onCancelToggle"
      />
    </div>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ShipOrderToStore from './modals/ShipOrderToStore.vue'
import ViewMaterialsTransferred from './modals/ViewMaterialsTransferred.vue'


export default {
  components: {
    ShipOrderToStore,
    ViewMaterialsTransferred,
  },
  data() {
    return {
      isSidebarOpen: false,
      page: 1,
      pageSize: 15,
      orderItemIds: [],
      selectAll: false,
      orderStates: this.$t('QualityPassed'),
    }
  },
  computed: {
    ...mapGetters(['getCurrentPageFilter', 'getOrderItemStates', 'getTotalItemsArticlesState', 'getFilterItemsArticlesState']),
    stateOptionsFiltered() {
      const optionsState = [
        'Created',
        'Assigned',
        'Confirmed',
        'ReadyToDeliver',
        'Delivered',
      ]
      const options = optionsState.map((state) => {
        return {
          value: state,
          text: this.$t(state),
        }
      });

      const filteredOptions = [...options];

      return filteredOptions;
    },
  },
  watch: {
    orderStates(value) {
      this.filterItemMovementArticlesState({
        state: value,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      this.orderItemIds = []
      this.selectAll = false
    },
    page(value) {
      if (value) {
        this.filterItemMovementArticlesState({
          state: this.orderStates,
          pageNumber: value,
          pageSize: this.pageSize,
        })
      }
    },
  },
  mounted() {
    this.filterItemMovementArticlesState({
      state: 'QualityPassed',
      pageNumber: this.page,
      pageSize: this.pageSize,
    })
  },
  methods: {
    ...mapActions(['filterItemMovementArticlesState', 'shipOrderToStore', 'orderedMaterials', 'shipOrderToManufactory']),
    toggleOrderItem(event, index) {
      const item = this.getFilterItemsArticlesState[index];
      const itemObject = {
        orderNumber: item.orderNumber,
        orderItemId: item.orderItemId,
        articleNumber: item.articleNumber,
      };

      if (event.target.checked) {
        this.orderItemIds.push(itemObject);
      } else {
        const itemIndex = this.orderItemIds.findIndex(
          (i) => { return i.orderNumber === item.orderNumber && i.orderItemId === item.orderItemId && i.articleNumber === item.articleNumber },
        );
        if (itemIndex !== -1) {
          this.orderItemIds.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.orderItemIds.length === this.getFilterItemsArticlesState.length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.orderItemIds = this.getFilterItemsArticlesState.map((item) => {
          return {
            orderNumber: item.orderNumber,
            orderItemId: item.orderItemId,
            articleNumber: item.articleNumber,
          }
        });
      } else {
        this.orderItemIds = [];
      }
    },
    isItemChecked(item) {
      return this.orderItemIds.some(
        (i) => { return i.orderNumber === item.orderNumber && i.orderItemId === item.orderItemId },
      );
    },
    moveOrders(value) {
      this.shipOrderToStore({
        newOrderItemState: value.newOrderItemState,
        object: value.form,
        successCallback: () => {
          this.filterItemMovementArticlesState({
            state: this.orderStates,
            pageNumber: 1,
            pageSize: 15,
          })
          this.orderItemIds = []
          this.selectAll = false
        },
      })
    },
    showMaterials() {
      this.isSidebarOpen = true
      const materials = this.orderItemIds.map((item) => { return item.orderItemId })
      this.orderedMaterials({
        object: materials,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
.cart-counter {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0px 7px;
    font-size: 13px;
    position: absolute;
    left: 31.5%;
    top: 6.5%;
}
</style>
